<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <!-- นำทาง -->
    <v-card elevation="0" class="v-card-bottom-30">
      <v-card-title class="body-1">
        สต๊อก
        <v-icon>mdi-chevron-right</v-icon>
        นำเข้า/นำออก/ตีกลับสินค้า
      </v-card-title>
    </v-card>

    <!-- แสดงภาพ -->
    <v-dialog v-model="ShowPhoto" max-width="500">
      <v-card class="ma-0">
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300" />
              <img class="hidden-sm-and-down" :src="ShowPhotoLink" width="450" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">เปิดแถบใหม่</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="#1976d2" @click="ShowPhoto = false">ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ค้นหา -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title>
        <v-icon color="blue" size="24">fa-sliders-h</v-icon>
        <span class="ml-2">ค้นหาข้อมูล</span>
        <v-spacer />
        <v-btn :elevation="1" color="primary" class="pa-2" @click="ImportStock()">
          <v-icon size="14" class="mr-1">fa-solid fa-plus</v-icon>
          เพิ่มรายการใหม่
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="4" lg="3" class="pt-1 pb-2"> วันที่ : </v-col>
              <v-col cols="12" md="4" class="pt-1 pb-2">
                <v-menu
                  ref="menuStartDate"
                  v-model="menuStartDate"
                  :close-on-content-click="false"
                  :return-value.sync="startDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      v-model="dateStart"
                      label="เริ่มต้น"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      @blur="startDate = parseDate(dateStart)"
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                      clearable
                      @click:clear="dateStart = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    @change="dateStart = formatDate(startDate)"
                    no-title
                    scrollable
                    :allowed-dates="allowedDates"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" class="pt-1 pb-2">
                <v-menu
                  ref="menuEndDate"
                  v-model="menuEndDate"
                  :close-on-content-click="false"
                  :return-value.sync="endDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      v-model="dateEnd"
                      label="สิ้นสุด"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      @blur="endDate = parseDate(dateEnd)"
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                      clearable
                      @click:clear="dateEnd = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    @change="dateEnd = formatDate(endDate)"
                    no-title
                    scrollable
                    :allowed-dates="allowedDates"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4" lg="3" class="pt-1 pb-2"> ร้านค้า : </v-col>
              <v-col cols="12" md="8" lg="8" class="pt-1 pb-2">
                <v-autocomplete
                  v-model="search.shop"
                  :items="search.shopList"
                  :item-text="(item) => item.name"
                  :item-value="(item) => item.id"
                  outlined
                  dense
                  hide-details
                ></v-autocomplete>
                <!-- <v-select
                  v-model="search.shop"
                  :items="search.shopList"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  return-object
                  dense hide-details
                  outlined
                  single-line
                ></v-select> -->
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4" lg="3" class="pt-1 pb-2"> เลขที่บิล : </v-col>
              <v-col cols="12" md="8" lg="8" class="pt-1 pb-2">
                <v-text-field
                  :value="search.importExportMasterId"
                  @change="(value) => (search.importExportMasterId = Trim_value(value))"
                  @keyup.enter="SearchStock()"
                  outlined
                  dense
                  hide-details
                  autocomplete="off"
                  placeholder="ใส่ , เพื่อค้นหาหลายรายการ"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4" lg="3" class="pt-1 pb-2"> เลขที่อ้างอิง : </v-col>
              <v-col cols="12" md="8" lg="8" class="pt-1 pb-2">
                <v-text-field
                  :value="search.referenceNumber"
                  @change="(value) => (search.referenceNumber = Trim_value(value))"
                  @keyup.enter="SearchStock()"
                  outlined
                  dense
                  hide-details
                  autocomplete="off"
                  placeholder="ใส่ , เพื่อค้นหาหลายรายการ"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" lg="6" md="6">
            <v-row>
              <v-col cols="12" md="4" lg="3" class="pt-1 pb-2"> ประเภท : </v-col>
              <v-col cols="12" md="7" lg="8" class="pt-1 pb-2">
                <v-radio-group v-model="rd_transactionType" mandatory row>
                  <v-radio label="ทั้งหมด" value="0"></v-radio>
                  <v-radio label="นำเข้า" value="1"></v-radio>
                  <v-radio label="นำออก" value="2"></v-radio>
                  <v-radio label="ตีกลับ" value="3"></v-radio>
                  <v-radio label="โอนย้าย" value="4"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4" lg="3" class="pt-1 pb-2"> สถานะ : </v-col>
              <v-col cols="12" md="7" lg="8" class="pt-1 pb-2">
                <v-radio-group v-model="radioGroup" mandatory row>
                  <v-radio label="ทั้งหมด" value="1"></v-radio>
                  <v-radio label="อนุมัติแล้ว" value="2"></v-radio>
                  <v-radio label="ยังไม่อนุมัติ" value="3"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="4" lg="3" class="pt-1 pb-2"> หมายเหตุ : </v-col>
              <v-col cols="12" md="7" lg="8" class="pt-1 pb-2">
                <v-textarea
                  :value="search.remark"
                  @change="(value) => (search.remark = Trim_value(value))"
                  dense
                  hide-details
                  autocomplete="off"
                  outlined
                  rows="1"
                  row-height="15"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="4" lg="3" class="pt-1 pb-2"> </v-col>
              <v-col cols="12" md="7" lg="8" class="pt-1 pb-2">
                <v-btn
                  :elevation="1"
                  color="primary"
                  class="pa-2 mt-1"
                  dark
                  @click="SearchData(limits, 0, search, 1)"
                >
                  <v-icon size="14" class="pr-1">fa-solid fa-magnifying-glass</v-icon>
                  ค้นหา
                </v-btn>
                <vue-excel-xlsx
                  id="GetExcel"
                  v-show="false"
                  :data="datasetExcel"
                  :columns="headerExcel"
                  :file-name="'TransferList'"
                  :sheet-name="'shee1'"
                >
                </vue-excel-xlsx>
                <v-btn
                  :elevation="1"
                  outlined
                  color="success"
                  class="pa-2 ml-2 mt-1"
                  @click="ExportExcel()"
                >
                  <v-icon size="16" class="pr-1">fa-file-excel</v-icon>
                  <span class="fn-12"> Export Excel </span>
                  <span v-if="loadingExcel != 0">({{ loadingExcel }}%)</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- ข้อมูลทั้งหมด -->
    <v-row class="ma-0">
      <v-col cols="12" class="pa-0 pl-1"> ทั้งหมด {{ formatMoney(total) }} แถว </v-col>
    </v-row>

    <!-- แสดงตาราง -->
    <!-- PC hidden-xs-only -->
    <v-data-table
      :headers="header"
      :items="dataset"
      :items-per-page="9999999999"
      hide-default-footer
      class="packhai-border-table hidden-xs-only"
    >
      <template v-slot:item.delete="{ item }">
        <span v-if="item.approvedDatetime == null">
          <a @click="DeleteTransfer(item.id)"
            ><v-icon size="18" color="red darken-3">fa-trash-alt</v-icon></a
          >
        </span>
      </template>
      <template v-slot:item.no="{ item }">
        {{ formatMoney(total - dataset.indexOf(item) - offsets) }}
      </template>
      <template v-slot:item.id="{ item }">
        <a @click="EditStock(item.id)"> {{ GenIE(item.id) }} </a>
      </template>
      <template v-slot:item.warehouse="{ item }">
        <span v-if="item.transactionType == 1">{{ item.toWarehouseName }}</span>
        <span v-else-if="item.transactionType == 2">{{ item.fromWarehouseName }}</span>
        <span v-else-if="item.transactionType == 3">{{ item.toWarehouseName }}</span>
        <span v-else-if="item.transactionType == 5">{{ item.toWarehouseName }}</span>
        <span v-else>{{ item.fromWarehouseName + " , " + item.toWarehouseName }}</span>
      </template>
      <template v-slot:item.transactionName="{ item }">
        <span v-if="item.transactionType == 1" class="color-green">{{
          item.transactionName
        }}</span>
        <span v-else-if="item.transactionType == 2" class="color-red">{{
          item.transactionName
        }}</span>
        <span v-else-if="item.transactionType == 3" class="color-orange">{{
          item.transactionName
        }}</span>
        <span v-else class="color-orange">{{ item.transactionName }}</span>
      </template>
      <template v-slot:item.staffBranchName="{ item }">
        <span v-if="item.staffBranchName != null && item.staffBranchName != ''">{{
          item.staffBranchName
        }}</span>
        <span v-else-if="item.staffShopName != null && item.staffShopName != ''">{{
          item.staffShopName
        }}</span>
      </template>
      <template v-slot:item.createdDatetime="{ item }">
        <span class="fn-12"> {{ formatDatetime(item.createdDatetime) }} </span>
      </template>
      <template v-slot:item.approvedDatetime="{ item }">
        <span class="fn-12"> {{ formatDatetime(item.approvedDatetime) }} </span>
      </template>
      <template v-slot:item.status="{ item }">
        <span v-if="item.approvedDatetime != null"
          ><v-chip color="success"> อนุมัติ </v-chip></span
        >

        <span v-else><v-chip color="warning"> รออนุมัติ </v-chip></span>
      </template>
      <template v-slot:item.approved="{ item }">
        <span
          v-if="
            item.approvedStaffBranchName != null && item.approvedStaffBranchName != ''
          "
          >{{ item.approvedStaffBranchName }}</span
        >
        <span
          v-else-if="
            item.approvedStaffShopName != null && item.approvedStaffShopName != ''
          "
          >{{ item.approvedStaffShopName }}</span
        >
      </template>
      <template v-slot:item.printimport="{ item }">
        <a
          v-if="
            item.transactionType == 1 ||
            item.transactionType == 2 ||
            item.transactionType == 4 ||
            item.transactionType == 5
          "
          @click="PrintImportStock(item.id)"
        >
          <v-icon size="22" color="info darken-3">mdi-printer </v-icon>
        </a>
      </template>
    </v-data-table>

    <!-- Mobile hidden-sm-and-up -->
    <v-data-iterator
      :items="dataset"
      :items-per-page="9999999999"
      hide-default-header
      hide-default-footer
      class="hidden-sm-and-up"
    >
      <template v-slot:default="props">
        <v-row class="ma-0">
          <v-col v-for="(item, index) in props.items" :key="index" cols="12" class="pa-1">
            <v-card>
              <v-row class="packhai-showproduct-3 pa-2 pb-1">
                <v-col cols="6" class="px-0 pt-1">
                  {{ formatMoney(total - dataset.indexOf(item) - offsets) }}.
                  <a @click="EditStock(item.id)"> {{ GenIE(item.id) }} </a>
                </v-col>
                <v-col cols="6" class="text-right px-0 pt-1">
                  {{ formatDatetime(item.createdDatetime) }}
                  <span
                    v-if="
                      item.approvedStaffBranchId == null &&
                      item.approvedStaffShopId == null
                    "
                    class="pa-1"
                  >
                    <a @click="DeleteTransfer(item.id)"
                      ><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a
                    >
                  </span>
                </v-col>
              </v-row>
              <v-row class="packhai-showproduct-3 pa-2 pb-1">
                <v-col cols="12" class="text-center px-0">
                  <span
                    v-if="
                      item.approvedStaffBranchName != null &&
                      item.approvedStaffBranchName != ''
                    "
                    ><v-chip color="success"> อนุมัติ </v-chip></span
                  >
                  <span
                    v-else-if="
                      item.approvedStaffShopName != null &&
                      item.approvedStaffShopName != ''
                    "
                    ><v-chip color="success"> อนุมัติ </v-chip></span
                  >
                  <span v-else><v-chip color="warning"> รออนุมัติ </v-chip></span>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  item.transactionType == 1 &&
                  ((item.approvedStaffBranchName != null &&
                    item.approvedStaffBranchName != '') ||
                    (item.approvedStaffShopName != null &&
                      item.approvedStaffShopName != ''))
                "
                class="ma-0"
              >
                <v-col cols="12" class="px-0 text-center">
                  <a @click="PrintImportStock(item.id)">
                    <v-icon size="22" color="info darken-3">mdi-content-paste</v-icon></a
                  >
                </v-col>
              </v-row>
              <v-row class="packhai-showproduct-3 pa-2 pb-1">
                <v-col cols="4" class="px-0 py-1 text-cut">
                  <span v-if="item.staffBranchName != null && item.staffBranchName != ''">
                    ผู้สร้าง : {{ item.staffBranchName }}</span
                  >
                  <span
                    v-else-if="item.staffShopName != null && item.staffShopName != ''"
                  >
                    ผู้สร้าง : {{ item.staffShopName }}</span
                  >
                </v-col>
                <v-col cols="4" class="px-0 py-1 text-center">
                  <span v-if="item.transactionType == 1" class="color-green">{{
                    item.transactionName
                  }}</span>
                  <span v-else-if="item.transactionType == 2" class="color-red">{{
                    item.transactionName
                  }}</span>
                  <span v-else-if="item.transactionType == 3" class="color-orange">{{
                    item.transactionName
                  }}</span>
                  <span v-else class="color-orange">{{ item.transactionName }}</span>
                </v-col>
                <v-col cols="4" class="text-right px-0 py-1 text-cut">
                  <span
                    v-if="
                      item.approvedStaffBranchName != null &&
                      item.approvedStaffBranchName != ''
                    "
                  >
                    ผู้อนุมัติ : {{ item.approvedStaffBranchName }}</span
                  >
                  <span
                    v-else-if="
                      item.approvedStaffShopName != null &&
                      item.approvedStaffShopName != ''
                    "
                  >
                    ผู้อนุมัติ : {{ item.approvedStaffShopName }}</span
                  >
                </v-col>
              </v-row>
              <v-row class="packhai-showproduct-3 pa-2 pb-1">
                <v-col cols="12" class="px-0">
                  {{ item.shopName }}
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>

    <!-- เปลี่ยนหน้า -->
    <v-row
      v-if="pageLength > 1 && dataset.length > 0"
      class="text-center pa-2 ma-0"
      @click="changePage()"
    >
      <v-col cols="2" md="3" lg="2" xl="1"> </v-col>
      <v-col cols="8" md="6" lg="8" xl="10" class="pa-0">
        <v-pagination
          v-model="page"
          :length="pageLength"
          :total-visible="pageTotalVisible"
          color="info"
        ></v-pagination>
      </v-col>
      <v-col cols="2" md="3" lg="2" xl="1" class="text-right">
        <v-select
          v-model="limits"
          label="จำนวนแสดง"
          :items="[10, 20, 50, 100, 500, 1000]"
          @change="LimitChange()"
          outlined
        ></v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Loading from "@/website/components/Loading";
import {
  stockService_dotnet,
  branchService_dotnet,
  shopService_dotnet,
} from "@/website/global";
import { AlertSuccess, AlertError } from "@/website/global_alert";
import {
  formatMoney,
  isNumberWithDot,
  isNumberWNoDot,
  DateNowNotNull,
  GenIE,
  Trim_value,
  Trim_rules,
  formatDatetime,
  ChangeIE,
} from "@/website/global_function";
export default {
  components: {
    Loading,
  },
  data: (vm) => ({
    header_token: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("authorization_token"),
    },
    page_loading: true,
    loading: false,
    radioGroup: 1,
    // แสดงภาพ
    ShowPhoto: false,
    ShowPhotoLink: "",

    // วันที่
    startDate: null,
    dateStart: null,
    menuStartDate: false,
    checkStart: true,
    endDate: null,
    dateEnd: null,
    menuEndDate: false,
    checkEnd: true,
    rd_transactionType: 0,
    // ค้นหา
    search: {
      shopList: [{ id: 0, name: "--ทั้งหมด--" }],
      shop: 0,
      warehouseList: [{ id: 0, name: "--ทั้งหมด--" }],
      warehouse: { id: 0, name: "--ทั้งหมด--" },
      startDate: null,
      endDate: null,
      remark: null,
      importExportMasterId: null,
      referenceNumber: null,
    },

    // แสดงตาราง
    total: 0,
    dataset: [],
    header: [
      { text: "ลบ", align: "center", sortable: false, value: "delete", width: "60px" },
      {
        text: "ปริ้น",
        align: "center",
        sortable: false,
        value: "printimport",
        width: "60px",
      },
      { text: "No", align: "center", sortable: false, value: "no", width: "60px" },
      {
        text: "เลขที่บิล",
        align: "center",
        sortable: false,
        value: "id",
        width: "90px",
      },
      {
        text: "รายการ",
        align: "center",
        sortable: false,
        value: "transactionName",
        width: "90px",
      },
      {
        text: "วันเวลา",
        align: "center",
        sortable: false,
        value: "createdDatetime",
        width: "150px",
      },
      {
        text: "ร้านค้า",
        align: "left",
        sortable: false,
        value: "shopName",
        width: "200px",
      },
      {
        text: "เลขอ้างอิง",
        align: "center",
        sortable: false,
        value: "referenceNumber",
        width: "150px",
      },
      {
        text: "ชื่อ Lot",
        align: "center",
        sortable: false,
        value: "lotName",
        width: "120px",
      },

      { text: "สถานะ", align: "center", sortable: false, value: "status", width: "50px" },
      {
        text: "ผู้สร้าง",
        align: "center",
        sortable: false,
        value: "staffBranchName",
        width: "130px",
      },
      {
        text: "ผู้อนุมัติ",
        align: "center",
        sortable: false,
        value: "approved",
        width: "130px",
      },
      {
        text: "อนุมัติเวลา",
        align: "center",
        sortable: false,
        value: "approvedDatetime",
        width: "160px",
      },
      {
        text: "คลัง",
        align: "left",
        sortable: false,
        value: "warehouse",
        width: "150px",
      },
      {
        text: "หมายเหตุ",
        align: "left",
        sortable: false,
        value: "remark",
        width: "200px",
      },
    ],

    // Export Excel
    loadingExcel: 0,
    datasetExcel: [],
    headerExcel: [
      { label: "No", field: "no", width: "60px" },
      { label: "เลขที่บิล", field: "id", width: "100px" },
      { label: "วันเวลา", field: "createdDatetime", width: "155px" },
      { label: "ร้านค้า", field: "shopName" },
      { label: "ออกจากคลัง", field: "fromWarehouseName", width: "250px" },
      { label: "เข้าที่คลัง", field: "toWarehouseName", width: "250px" },
      { label: "รายการ", field: "transactionName", width: "130px" },
      { label: "เลขอ้างอิง", field: "referenceNumber", width: "130px" },
      { label: "ชื่อ Lot", field: "lotName", width: "130px" },
      { label: "สถานะ", field: "status", width: "50px" },
      { label: "ผู้สร้าง", field: "createdBy", width: "130px" },
      { label: "ผู้อนุมัติ", field: "approvedBy", width: "130px" },
      { label: "อนุมัติเวลา", field: "approvedDatetime", width: "130px" },
      { label: "หมายเหตุ", field: "remark", width: "200px" },
      { label: "ชื่อสินค้า", field: "productname", width: "130px" },
      { label: "SKU", field: "sku", width: "130px" },
      { label: "Barcode", field: "barcode", width: "130px" },
      { label: "คุณลักษณะ1", field: "prop1Description", width: "130px" },
      { label: "คุณลักษณะ1", field: "prop2Description", width: "130px" },
      { label: "รหัสสินค้า", field: "productCode", width: "130px" },
      { label: "style Code", field: "styleCode", width: "130px" },
      { label: "colour Code", field: "colourCode", width: "130px" },
      { label: "brand", field: "brand", width: "130px" },
      { label: "จำนวนที่จะทำรายการ", field: "quantityRequest", width: "130px" },
      { label: "จำนวนที่ทำรายการจริง", field: "quantity", width: "130px" },
      { label: "วันที่ผลิต", field: "mfgDate", width: "130px" },
      { label: "วันหมดอายุ", field: "expiryDate", width: "130px" },
      { label: "หมายเหตุสินค้า", field: "itemRemark", width: "130px" },
    ],

    // paginations
    page: 1,
    pageTemp: 1,
    pageLength: 1,
    pageTotalVisible: 9,
    limits: 10,
    offsets: 0,
  }),
  watch: {
    startDate: async function (newVal, oldVal) {
      if (
        new Date(newVal) > new Date(this.endDate) &&
        this.endDate != null &&
        newVal != null
      ) {
        if (oldVal != null) {
          this.checkStart = false;
          this.startDate = oldVal;
        } else {
          this.dateStart = oldVal;
          this.$refs.menuStartDate.save(oldVal);
        }
      } else if (this.checkStart) {
        try {
          this.$refs.menuStartDate.save(newVal);
        } catch (err) {
          console.log("");
        }
      } else {
        this.dateStart = this.formatDate(newVal);
        this.$refs.menuStartDate.save(newVal);
        this.checkStart = true;
      }
    },
    endDate: async function (newVal, oldVal) {
      if (
        new Date(newVal) < new Date(this.startDate) &&
        this.startDate != null &&
        newVal != null
      ) {
        if (oldVal != null) {
          this.checkEnd = false;
          this.endDate = oldVal;
        } else {
          this.dateEnd = oldVal;
          this.$refs.menuEndDate.save(oldVal);
        }
      } else if (this.checkEnd) {
        try {
          this.$refs.menuEndDate.save(newVal);
        } catch (err) {
          console.log("");
        }
      } else {
        this.dateEnd = this.formatDate(newVal);
        this.$refs.menuEndDate.save(newVal);
        this.checkEnd = true;
      }
    },
  },
  async created() {
    document.title = this.$router.history.current.meta.title;
    await this.SearchData(this.limits, 0, this.search, 1);
    await this.GetShopList();
    this.page_loading = false;
    this.radioGroup = 1;
  },
  methods: {
    PrintImportStock(id) {
      window.open("/print-importstock?id=" + btoa(id));
    },
    async SearchData(limits, offsets, search, page) {
      var ispass = false;

      while (!ispass) {
        try {
          this.loading = true;
          var ie = this.ChangeIE(search.importExportMasterId);
          let response = await axios.post(
            stockService_dotnet + "Stock/get-importexportmaster-list",
            {
              branchId: parseInt(localStorage.getItem("Branch_BranchID")),
              shopId: search.shop == 0 ? null : search.shop,
              importExportStatusType: parseInt(this.radioGroup),
              warehouseId: null,
              startDate: this.startDate,
              endDate: this.endDate,
              remark: search.remark == "" ? null : search.remark,
              importExportMasterIDList: search.importExportMasterId,
              referenceNumber:
                search.referenceNumber == "" ? null : search.referenceNumber,
              skip: offsets,
              take: limits,
              transactionType: parseInt(this.rd_transactionType),
            },
            { headers: this.header_token }
          );
          ispass = true;
          this.loading = false;
          if (response.status == 200) {
            this.page = page;
            this.pageTemp = page;
            this.offsets = offsets;
            this.dataset = response.data.resultData;
            this.total = response.data.resultCount;
            this.pageLength = Math.ceil(this.total / this.limits);

            if (this.pageLength <= 0) {
              this.pageLength = 1;
            }
          } else {
            this.AlertError();
          }
        } catch (error) {
          this.loading = false;
          Swal.fire({
            toast: true,
            icon: "error",
            title: "ไม่สามารถติดต่อฐานข้อมูลได้",
            text: "กำลังดำเนินการใหม่อีกครั้ง",
            timer: 1500,
            showConfirmButton: false,
            position: "bottom-end",
          });
        }
      }
    },
    async GetShopList() {
      await axios
        .all([
          axios.post(
            shopService_dotnet + "Shop/get-shop-list",
            {
              shopType: 1,
              branchId: localStorage.getItem("Branch_BranchID"),
            },
            { headers: this.header_token }
          ),
        ])
        .then(
          axios.spread((shopList) => {
            if (shopList.status == 200) {
              // list shop
              if (shopList.data.result.length > 0) {
                for (var i in shopList.data.result) {
                  this.search.shopList.push({
                    id: shopList.data.result[i].shop.id,
                    name: shopList.data.result[i].shop.name,
                  });
                }
              }
            } else {
              this.AlertError();
            }
          })
        );
    },

    // เรียกใช้
    async DeleteTransfer(ImportExportMasterId) {
      Swal.fire({
        icon: "question",
        title: "ต้องการลบ ใช่หรือไม่",
        text: "เลขที่บิล " + this.GenIE(ImportExportMasterId),
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.loading = true;
          let response = await axios.post(
            stockService_dotnet + "Stock/delete-importexport-stock",
            {
              ImportExportMasterId: ImportExportMasterId,
            },
            { headers: this.header_token }
          );
          if (response.status == 200) {
            await this.SearchData(this.limits, 0, this.search, 1);
            this.loading = false;
            this.AlertSuccess();
          } else {
            this.loading = false;
            this.AlertError();
          }
        }
      });
    },
    async LimitChange() {
      await this.SearchData(this.limits, 0, this.search, 1);
    },
    async ExportExcel() {
      this.datasetExcel = [];
      this.loadingExcel = 0;
      Swal.fire({
        icon: "info",
        text: "ใช้เวลาสักครู่กรุณารอจนกว่าจะเสร็จ",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          var limits = 50;
          var offsets = 0;
          this.loadingExcel = 1;
          var total = this.total;
          var round = parseInt(total / limits);

          if (limits * round < total) {
            round = round + 1;
          }

          var no = 0;
          for (var i = 1; i <= round; i++) {
            var resultData = await this.GetExportExcel(limits, offsets, this.search);

            // สร้าง object เก็บข้อมูลใน dataDetail ตาม id ที่ซ้ำกัน
            const dataDetailMap = {};
            resultData.dataDetail.forEach((item) => {
              if (!dataDetailMap[item.id]) {
                dataDetailMap[item.id] = [];
              }
              dataDetailMap[item.id].push(item);
            });
            // นำข้อมูลใน dataDetailMap มาใส่ใน resultData ตาม id ที่ตรงกับ resultData
            resultData.resultData.forEach((item) => {
              if (dataDetailMap[item.id]) {
                item.dataDetail = dataDetailMap[item.id];
              }
            });
            var data = resultData.resultData;
            for (var j in data) {
              var staffBranchName =
                data[j].staffBranchName != null && data[j].staffBranchName != ""
                  ? data[j].staffBranchName
                  : data[j].staffShopName != null && data[j].staffShopName != ""
                  ? data[j].staffShopName
                  : null;
              var status = data[j].approvedDatetime != null ? "อนุมัติ" : "รออนุมัติ";
              var approved =
                data[j].approvedStaffBranchName != null &&
                data[j].approvedStaffBranchName != ""
                  ? data[j].approvedStaffBranchName
                  : data[j].approvedStaffShopName != null &&
                    data[j].approvedStaffShopName != ""
                  ? data[j].approvedStaffShopName
                  : null;

              for (var x in data[j].dataDetail) {
                no += 1;
                this.datasetExcel.push({
                  no: no,
                  id: this.GenIE(data[j].id),
                  createdDatetime: this.formatDatetime(data[j].createdDatetime),
                  shopName: data[j].shopName,
                  fromWarehouseName: data[j].fromWarehouseName,
                  toWarehouseName: data[j].toWarehouseName,
                  transactionName: data[j].transactionName,
                  status: status,
                  referenceNumber: data[j].referenceNumber,
                  lotName: data[j].lotName,

                  createdBy: staffBranchName,
                  approvedBy: approved,

                  approvedDatetime: this.formatDatetime(data[j].approvedDatetime),
                  remark: data[j].remark,
                  productname: data[j].dataDetail[x].name,
                  sku: data[j].dataDetail[x].sku,
                  barcode: data[j].dataDetail[x].barcode,
                  prop1Description: data[j].dataDetail[x].prop1Description,
                  prop2Description: data[j].dataDetail[x].prop2Description,
                  productCode: data[j].dataDetail[x].productCode,
                  styleCode: data[j].dataDetail[x].aP21_STYLE_CODE,
                  colourCode: data[j].dataDetail[x].aP21_COLOUR_CODE,
                  brand: data[j].dataDetail[x].brand,
                  quantityRequest: data[j].dataDetail[x].quantityRequest,
                  quantity: data[j].dataDetail[x].quantity,
                  mfgDate: this.formatMFG(data[j].dataDetail[x].mfgDate),
                  expiryDate: this.formatMFG(data[j].dataDetail[x].expiryDate),
                  itemRemark: data[j].dataDetail[x].itemRemark,
                });
              }
            }
            this.loadingExcel = parseInt(
              (parseFloat(i) / parseFloat(round)) * parseFloat(100)
            );
            offsets = offsets + limits;
          }
          this.loadingExcel = this.datasetExcel.length == 0 ? 0 : 100;
          document.getElementById("GetExcel").click();
          this.loadingExcel = 0;
        }
      });
    },
    async GetExportExcel(limits, offsets, search) {
      try {
        var ie = this.ChangeIE(search.importExportMasterId);
        let response = await axios.post(
          stockService_dotnet + "Stock/get-importexportmaster-list",
          {
            branchId: parseInt(localStorage.getItem("Branch_BranchID")),
            shopId: search.shop == 0 ? null : search.shop,
            warehouseId: search.warehouse.id == 0 ? null : search.warehouse.id,
            importExportStatusType: parseInt(this.radioGroup),
            isNeedDetail: true,
            startDate: this.startDate,
            endDate: this.endDate,
            remark: search.remark == "" ? null : search.remark,
            importExportMasterIDList: search.importExportMasterId,
            referenceNumber: search.referenceNumber == "" ? null : search.referenceNumber,
            skip: offsets,
            take: limits,
            transactionType: parseInt(this.rd_transactionType),
          },
          { headers: this.header_token }
        );
        if (response.status == 200) {
          return response.data;
        } else {
          return [];
        }
      } catch (e) {
        console.log("error");
      }
    },
    async EditStock(ImportExportMasterID) {
      window.open("transfer-list/update?id=" + btoa(ImportExportMasterID));
    },
    ImportStock() {
      window.open("transfer-list/update?id=" + btoa(0));
    },
    async changePage() {
      if (this.pageLength != 1 && this.pageTemp != this.page) {
        var offsets = this.page * this.limits - this.limits;
        await this.SearchData(this.limits, offsets, this.search, this.page);
        this.pageTemp = this.page;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    formatMFG(datetime) {
      if (datetime == null || datetime == "") {
        return null;
      }
      return (
        datetime.substring(0, 4) +
        "-" +
        datetime.substring(5, 7) +
        "-" +
        datetime.substring(8, 10)
      );
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    allowedDates: (val) => val <= new Date().toISOString().substr(0, 10),
    OpenPhoto(Photo) {
      this.ShowPhotoLink = Photo;
      this.ShowPhoto = true;
    },
    formatMoney,
    isNumberWithDot,
    isNumberWNoDot,
    DateNowNotNull,
    GenIE,
    Trim_value,
    Trim_rules,
    formatDatetime,
    ChangeIE,
    AlertSuccess,
    AlertError,
  },
};
</script>

<style scoped>
>>> .v-select__slot {
  height: 40px;
}
>>> .v-input__prepend-inner {
  margin-top: 10px;
}
</style>
